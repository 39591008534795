<template>
  <base-view title="Estado de Cuenta por Inversionista" icon="file-text">
    <div>
      <check-authorization :requiresAuthorizations="['indice estado cuenta']">
        <div class="card card-header-actions mb-4">
          <b-card-header>
            Estado de Cuenta

            <div class="w-50 ml-auto d-flex justify-content-end align-items-center">
              <!-- Search -->
              <search-bar
                placeholder="Buscar por folio fiscal o inversionista"
                @filter="(filter) => this.filter = filter"></search-bar>
              <!-- Options -->
              <div class="dropdown no-caret">
                <button
                  class="btn btn-transparent-dark btn-icon dropdown-toggle"
                  type="button"
                  data-toggle="dropdown"
                >
                  <i data-feather="more-vertical"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
                  <a class="dropdown-item my-cursor" @click="getUsers(null)">
                    Recargar
                  </a>
                </div>
              </div>
            </div>
          </b-card-header>

          <b-card-body class="p-2">
            <span class="text-black small font-weight-bolder mb-3">
              Seleccionar inversionista
              <span class="text-danger">*</span>
            </span>
            <estado-cuenta-inversionista-select-modal v-model="inversionista_id" @change="onChangeInversionista"></estado-cuenta-inversionista-select-modal>
            <b-table-lite
              id="estadoCuentaTable"
              :fields="fields"
              :items="data"
              striped
              bordered
              responsive
              hover
              stacked="lg"
              show-empty
              small
            >
            </b-table-lite>
            <b-button @click="onDownload" size="sm" :disabled="!inversionista_id">
              Descargar reporte
            </b-button>
            <b-button @click="onNotify" size="sm" variant="primary" :disabled="!inversionista_id">
              Enviar por correo
            </b-button>
          </b-card-body>
        </div>
      </check-authorization>
    </div>
  </base-view>
</template>

<script>
import SearchBar from '../../components/Common/Views/SearchBar.vue'
import EstadoCuentaInversionistaSelectModal from '../../components/Reportes/EstadoCuentaInversionistaSelectModal.vue'

export default {
  name: 'EstadoCuentaInversionistaMainView',

  components: {
    SearchBar,
    EstadoCuentaInversionistaSelectModal
  },

  created () {
    this.init()
  },

  data () {
    return {
      hideButton: false,
      showForm: false,
      filter: '',
      inversionista_id: null,
      data: [],
      fields: [
        { key: 'num_catastral', label: 'Número catastral' },
        { key: 'cuenta_predial', label: 'Cuenta Predial' },
        { key: 'periodo', label: 'Período' },
        { key: 'fecha_pago', label: 'Fecha de pago' },
        { key: 'importe', label: 'Importe' }
      ]
    }
  },

  methods: {
    async onChangeInversionista (id) {
      if (id) {
        const response = await this.$store.dispatch('estadoCuentaInversionistaModule/getPreview', { inversionista_id: id })
        this.data = response.code === 200 ? response.data : []
      }
    },
    async onDownload () {
      if (this.inversionista_id) {
        const result = await this.$store.dispatch('estadoCuentaInversionistaModule/download', {
          inversionista_id: this.inversionista_id
        })
        await this.$onDownload(result, 'estado_cuenta.pdf')
        this.$notify({ message: 'Descarga de estado de cuenta.' })
      }
    },
    async onNotify () {
      if (this.inversionista_id) {
        const response = await this.$store.dispatch('estadoCuentaInversionistaModule/notify', {
          inversionista_id: this.inversionista_id
        })
        if (response.code === 200) {
          this.$notify({ message: 'Estado de cuenta enviado por correo.' })
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
